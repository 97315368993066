import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionBox from "../components/section-box"
import SectionBox2 from "../components/section-box2"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

//Ilustration inports
import ilustration2 from "../images/oblakoder-home-5.png"

//Styled Components Import

import SvgBg1 from "../components/styled/SvgBg"
import GridStyled1 from "../components/styled/Grid1"
import ImageBox1 from "../components/styled/ImageBox"

const AwsServerless = () => (
  <Layout>
    <SEO title="Nephele and Serverless" />
    <SvgBg1>
      <GridStyled1>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              Serverless <span>AWS</span>
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Nephele</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p>
                <b>Nephele and Serverless</b>
              </p>
            </SectionBox>
            <SectionBox2>
              <p>
                We are delivering systems and solutions that integrates native
                AWS cloud architecture -Serverless. AWS Lambda, AWS Fargate,
                Amazon API Gateway, Amazon DynamoDB, Amazon S3, AWS Amplify, AWS
                AppSync and many more services that seamlessly integrates with
                serverless architecture allow us to build and run applications
                and services without thinking about servers.
              </p>
            </SectionBox2>
            <SectionBox2>
              <p>
                Serverless architecture eliminates infrastructure management
                tasks such as server or cluster provisioning, patching,
                operating system maintenance, and capacity provisioning.
              </p>
            </SectionBox2>
            <SectionBox>
              <p>
                <b>Why use serverless?</b>
              </p>
            </SectionBox>
            <SectionBox2>
              <p>
                Serverless enables you to build modern applications. A focus is
                on the application and service development so you no longer need
                to worry about ensuring application fault tolerance and
                availability.
              </p>
            </SectionBox2>
          </ScrollAnimation>
        </div>
        <ImageBox1>
          <div className="png-img">
            <img src={ilustration2} alt="Oblakoder and Serverless" />
          </div>
          <div className="res-img">
            <img src={ilustration2} alt="Oblakoder and Serverless" />
          </div>
        </ImageBox1>
      </GridStyled1>
    </SvgBg1>
  </Layout>
)

export default AwsServerless
